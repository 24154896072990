import React from "react";

import { Box } from "components/Box";

export const ShakeShack = () => {
  return (
    <Box>
      <Box style={{ fontWeight: 600 }}>Marketing Manager • Internship</Box>
    </Box>
  );
};
