import React from "react";

import { Box } from "components/Box";

export const HEC = () => {
  return (
    <Box>
      <Box style={{ fontWeight: 600 }}>
        Master&#39;s Degree in Entrepreneurship
      </Box>
      <Box>Won 1st prize at the final pitch competition.</Box>
    </Box>
  );
};
