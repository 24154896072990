import React from "react";

import { Box } from "components/Box";

export const Peninsula = () => {
  return (
    <Box>
      <Box style={{ fontWeight: 600 }}>Operational Assistant • Internship</Box>
    </Box>
  );
};
