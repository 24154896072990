export const theme = {
  boxShadow: {},
  size: {},
  color: {},
  fontSize: {},
  border: {},
  radius: {},
};

export const cssKeyToThemeKey = {
  fontFamily: "fontFamily",
  fontSize: "fontSize",
  lineHeight: "fontSize",
  borderRadius: "radius",
  borderTopLeftRadius: "radius",
  borderTopRightRadius: "radius",
  borderBottomLeftRadius: "radius",
  borderBottomRightRadius: "radius",
  boxShadow: "boxShadow",
  color: "color",
  backgroundColor: "color",
  borderColor: "color",
  width: "size",
  minWidth: "size",
  maxWidth: "size",
  height: "size",
  minHeight: "size",
  maxHeight: "size",
  top: "size",
  bottom: "size",
  left: "size",
  right: "size",
  margin: "size",
  gridColumnGap: "size",
  gridRowGap: "size",
  marginTop: "size",
  marginBottom: "size",
  marginLeft: "size",
  marginRight: "size",
  padding: "size",
  paddingTop: "size",
  paddingBottom: "size",
  paddingLeft: "size",
  paddingRight: "size",
  gridGap: "size",
  border: "border",
  borderTop: "border",
  borderRight: "border",
  borderBottom: "border",
  borderLeft: "border",
  borderTopColor: "color",
  borderRightColor: "color",
  borderBottomColor: "color",
  borderLeftColor: "color",
};

export default theme;
