import React from "react";

import { Box } from "components/Box";

export const EHL = () => {
  return (
    <Box>
      <Box style={{ fontWeight: 600 }}>
        Bachelor of Science in Hospitality Management
      </Box>
      <Box>Grade 5.1/6, top 15% of class.</Box>
    </Box>
  );
};
