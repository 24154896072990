export enum Skill {
  algolia = "Algolia",
  auth0 = "Auth0",
  c = "C",
  docker = "Docker",
  excelJs = "ExcelJS",
  firebase = "Firebase",
  git = "Git",
  graphQL = "GraphQL",
  html = "HTML",
  javascript = "Javascript",
  mongoDb = "MongoDB",
  mySql = "MySQL",
  nodeJs = "Node.js",
  objectiveC = "Objective-C",
  php = "PHP",
  postgreSql = "PostgreSQL",
  python = "Python",
  reactJs = "React",
  redis = "Redis",
  redux = "Redux",
  shell = "Shell",
  swift = "Swift",
  typescript = "Typescript",
  unix = "Unix",
  xstyled = "xstyled",
  xstate = "XState",
}
