import React from "react";

import { Box } from "components/Box";

export const Mongol = () => {
  return (
    <Box>
      <Box style={{ fontWeight: 600 }}>Organizer and participant</Box>
    </Box>
  );
};
