import React from "react";

import { Box } from "components/Box";

export const Mandarin = () => {
  return (
    <Box>
      <Box style={{ fontWeight: 600 }}>Summer internship</Box>
    </Box>
  );
};
